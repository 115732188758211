import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import Home from "./app/views/home/Home";
import Billing from "./app/views/Billing/Billing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fontsource/nunito-sans";
import "@fontsource/open-sans";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

document.onkeydown = (event) => {
  if (event && event.key === "Escape") {
    return false;
  }
};

root.render(
  <React.StrictMode>
    {process.env.NODE_ENV === "development"
      ? console.log(process.env.REACT_APP_DEV_MODE)
      : console.log(process.env.REACT_APP_PRO_MODE)}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="facturacion" element={<Billing />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
