import React, { lazy, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";

const BillingFormStepOneComponent = lazy(() =>
  import("../../components/billing/BillingFormStepOneComponent")
);
const BillingFormStepTwoComponent = lazy(() =>
  import("../../components/billing/BillingFormStepTwoComponent")
);
const BillingFormStepThreeComponent = lazy(() =>
  import("../../components/billing/BillingFormStepThreeComponent")
);

const Billing = (props) => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [state, setState] = useState({});
  const [isBilling, setIsBilling] = useState(false);

  const handleGoToBack = () => {
    navigate("/");
  };

  return (
    <div className="pt-5 body-height">
      {!isNext ? (
        <React.Fragment>
          <div className="row">
            <div className="col-12 col-md-12">
              <label htmlFor="sucursal" className="billing-title">
                Inicia tu factura
              </label>
            </div>
            <div className="col-12 col-md-12">
              <label htmlFor="sucursal" className="form-label">
                <h5 className="form-subtitle">
                  Sigue los pasos para generar tu factura
                </h5>
              </label>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <label htmlFor="paso1" className="form-label">
              <h4 className="form-title">Ingresa los datos de tu ticket</h4>
            </label>
          </div>
          <div className="col-12 col-md-12 body-height">
            <Suspense fallback={LoadingComponent()}>
              <BillingFormStepOneComponent
                isValid={isValid}
                setIsValid={setIsValid}
                setIsNext={setIsNext}
                setState={setState}
              />
            </Suspense>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!isBilling ? (
            <React.Fragment>
              <div className="col-12 col-md-12">
                <label htmlFor="paso2" className="form-label">
                  <h4 className="form-title">
                    Ingresa los datos de facturación
                  </h4>
                </label>
              </div>
              <div className="col-12 col-md-12">
                <Suspense fallback={LoadingComponent()}>
                  <BillingFormStepTwoComponent
                    state={state}
                    setState={setState}
                    setIsBilling={setIsBilling}
                    handleGoToBack={handleGoToBack}
                  />
                </Suspense>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="col-12 col-md-12 mx-auto text-center pt-4 pb-4 m-5">
                <label htmlFor="paso2" className="form-label">
                  <h1 className="form-title-billing fs-0">
                    Su factura fue realizada con éxito!
                  </h1>
                </label>
              </div>
              <div className="col-12 col-md-12 body-height">
                <Suspense fallback={LoadingComponent()}>
                  <BillingFormStepThreeComponent
                    state={state}
                    setState={setState}
                    handleGoToBack={handleGoToBack}
                  />
                </Suspense>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Billing;
